<template>
  <div ref="edit" class="role-edit">
    <!-- 大表单 -->
    <div class="form-body">
      <el-form ref="form" label-width="150rem">
        <el-form-item label="任务名称" style="width: 500rem">
          {{ form.name }}
        </el-form-item>

        <el-form-item label="开始时间" style="width: 500rem">
          {{ form.start_at }}
        </el-form-item>

        <el-form-item label="结束时间" style="width: 500rem">
          {{ form.end_at }}
        </el-form-item>
        <el-form-item label="应用校区">
          {{ form.school }}
        </el-form-item>
        <el-form-item label="应用年级" style="width: 500rem">
          {{ form.grade }}
        </el-form-item>
        <el-form-item label="合格率" style="width: 500rem">
          {{ form.qualified ? form.qualified + '%' : '' }}
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    <div class="footer-button">
      <el-button class="lowerbtn" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  data() {
    return {
      form: {},
    };
  },

  created() {
    this.$_axios2.get('api/work/talk/show?id=' + this.$route.query.id).then(res => {
      this.form = res.data.data
    })
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  min-width: 100rem;
  margin: 10rem;
}

::v-deep .el-input--small .el-input__inner {
  width: 300rem;
  height: 40rem;
  color: #999;
}

::v-deep .el-checkbox-group {
  overflow: scroll;
  max-height: 400rem !important;
}
</style>
